.body{
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../../../public/image/noFound/bg.jpg);
    &__bg{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(22, 26, 31, 0.6);
        text-transform: uppercase;
        font-size: 45px;
        color: white;
        h2{
            margin-top: 0.5rem;
            margin-bottom: 3rem;
            font-size: 30px;
            text-transform: none;
        }
        h3{
            font-size: 25px;
            span{
                margin-top: 2rem;
                font-size: 65px;
            }
        }
        a{
            color: #ffffff;
            margin-top: 3rem;
            font-size: 35px;
            font-weight: 900;
            text-transform: none;
        }
    }
}

.eyes{
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 300px;
    height: 160px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;

    &__eye{
        display: flex;
        justify-content: center;
        align-items: end;
        // border: 1px solid black;
        border-radius: 55%;
        box-shadow:-10px 9px 50px 0px #616161 inset, 8px 5px 27px 0px #000000;
        margin-left: 18px;
        margin-right: 18px;
        width: 50%;
        height: 100%;
        background-color: #fff;
        &__pupil{
        width: 30px;
        height: 30px;
        margin-bottom: 40px;
        background-color: black;
        border-radius: 50%;
        animation-name: slide-left;
		animation-duration: 5s;
		animation-timing-function: linear;
		animation-delay: 0s;
		animation-iteration-count: infinite;
		animation-direction: normal;
		animation-fill-mode: none;
        }
    }
}

@keyframes slide-left {
			
    0% {
        transform:translateX(20px);
    }
    25%{
        transform:translateX(20px) translateY(10px);
    }
    50%{
        transform:translateX(-10px);
    }
    75%{
        transform:translateX(-10px) translateY(-10px);
    }
    100% {
        transform:translateX(20px);
    }
}