

.scrollToTop {
  z-index: 12;
  position: fixed;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
  font-size: 55px;
  color: #0f3266; 
  opacity: .6;
}
