$easing: cubic-bezier(0.39, 0.575, 0.565, 1);

.animate{
    position: absolute;
    overflow: hidden;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(240, 205, 153);
    z-index: 999;
    animation: background-swipe-out 1.5s cubic-bezier(0.785, 0.135, 0.15, 0.86)  1;
}

@keyframes background-swipe-out {
	0%, 65% { width: 100%; }
	100% { width: 0%; }
}