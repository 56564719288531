.icon{
    margin-top: 3rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    &__box{
        svg{
            font-size: 40px;
            margin-right: 1rem;
            color: white;
            font-weight: 900; 
            transition: all .3s ease;
            &:hover{
                filter: invert(30%);
                transform: scale(1.04);
            }
        }        
    }
    &__address{
        color: white;
        font-size: 13px;
        text-align: right;
        margin-top: 2rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
    }
}
@media (max-width: 1200px) {
    .icon{
        svg{
            font-size: 22px;
        }
        &__address{
            h3{
                font-size: 11px;
            }
            margin-top: 8px;
            font-size: 8px;
            margin-bottom: 4px;
        }
    }
}