.body{
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    &__left{
        width: 20%;
        position: fixed;
        background-image: url(../../../../public/image/gallery/bg.jpg);
        height: 100%;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        &__title{
            width: 100%;
            margin-top: 20%;
            padding-right: 3rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            cursor: default;
            writing-mode: vertical-rl;
            transform: scale(-1, -1);
            color: #161a1f;
            h1{
                font-size: 45px;
                letter-spacing: 2px;
                font-weight: 900;
                margin: 0;
                padding-top: 0;
                padding: 1rem;
            }
        }
    }
    &__right{
        position: absolute;
        background:linear-gradient(90deg, #e9e5e0 0%, #d7c1b1 90%);
        top: 0;
        right: 0;
        width: 80%;
        height: 100%;
    }
}
.modalbtn{
    width: 280px;
    height: 60px;
    margin-top: 2rem;
    margin-left: 5rem;
    border: 1px solid rgb(21, 25, 30);
    border-radius: 12px;
    font-size: 16px;
    padding: 0.5rem 2rem;
    letter-spacing: 2px;
    transition: all .3s ease;
    font-weight: 900;
    margin-bottom: 2rem;
        &:hover{
            transform: scale(1.05);
            filter:invert(33%) contrast(200%);
        }
        &:active{
            filter: invert(100%);
        }

}

.footer{
    position: relative;
    background: rgba(22, 26, 31, 0.7);
    overflow: hidden;
    width: 100%;
    height: 730px;
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
    margin-bottom: 0;
    padding-bottom: 0;
    
    &__left{
        display: flex;
        flex-direction: row;
        width: 70%;
        height: 100%;
        margin: 1rem;
    &__right{
        width: 30%;
        padding-right: 1rem;
        p{
        font-size: 12px;
        }
    }
}
}

.circle{
    position: absolute;
    left: -5rem;
    bottom: -10rem;
    width: 700px;
    height: 700px;
}

.gallery{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background:linear-gradient(90deg, #e9e5e0 0%, #d7c1b1 90%);
    h2, h3{
        padding-left: 3rem;
        font-size: 38px;
        color: #ffffff;
        
    }
    a{
        width: 80px;
        height: 80px;
    }
    &__body{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 100%;
        h2{
            padding-top: 0rem;
            padding-bottom: 3rem;
            color: rgba(22, 26, 31, 1);
            font-size: 20px;
            font-style: italic;

        }
        &__swiper{
            padding-left: 2rem;
            padding-top: 2rem;
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }
}
.logotype{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;    
    align-items: center;
    margin-top: 0rem;
    margin-bottom: 1rem;
    &__line{
        width: 30%;
        height: 1px;
        background: #161a1f;
    }
    &__icon{
        margin-left: 2rem;
        margin-right: 2rem;
        font-size: 60px;
        color: #161a1f;
    }
}


@media (max-width: 1200px) {
    .body{
        flex-direction: column;
        &__left{
            width: 100%;
            position: relative;
            &__title{
                writing-mode: initial;
                transform: initial;
            }
        }
        &__right{
            width: 100%;
            position: relative;
        }
        .gallery{
            &__body{
                &__swiper{
                    flex-direction: column;
                }
            }
        }
    }
    .footer{
        flex-direction: column;
        height: 100%;
        &__left{
            width: 100%;
        }
        &__right{
            z-index: 3;
            width: 100%;
        }
    }
}