.block{
    z-index: 2;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 2rem;
    background:linear-gradient(90deg, #e9e5e0 0%, #d7c1b1 90%);
    h2{
        padding-left: 2.5rem;
        font-size: 25px;
    }
    h3{
        padding: 2rem;
        font-size: 18px;
        font-style: italic;
    }
    h4{
        font-size: 26px;
        padding-left: 2.5rem;
    }
    p{
        font-size: 18px;
        padding-left: 2rem;
        padding-top: 2rem;
        margin: 0;
    }
    &__block{
        width: 100%;
        position: relative;
        overflow: hidden;
        margin-top: 4rem;
        margin-bottom: 4rem;
        display: flex;
        flex-direction: row;
        &__text{
            width: 50%;
            text-align: center;
            h4{
                font-size: 22px;
                padding-left: 2.5rem;
            }
            ul{
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            span{
                margin-top: 1rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
                font-weight: 900;
                font-size: 16px;
            }
            li{
                width: 80%;
                font-size: 14px;
            }
            svg{
                font-size: 35px;
                margin-bottom: 2rem;
            }
        }
        &__svg{
            position: absolute;
            overflow: hidden;
            transform: scale3d(10deg);
            transform: scale(-1, -1);
            
            right: -20%;
            width: 50%;
            height: 100%;
        }
        
    }
}

.logotype{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;    
    align-items: center;
    margin-top: 0rem;
    margin-bottom: 1rem;
    &__line{
        width: 30%;
        height: 1px;
        background: #161a1f;
    }
    &__icon{
        margin-left: 2rem;
        margin-right: 2rem;
        font-size: 60px;
        color: #161a1f;
    }
}

.footer{
    position: relative;
    background: rgba(22, 26, 31, 0.7);
    overflow: hidden;
    width: 100%;
    height: 730px;
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 0;
    padding-bottom: 0;
    
    &__left{
        display: flex;
        flex-direction: row;
        width: 70%;
        height: 100%;
        margin: 1rem;
        &__1{
            width: 50%;
            height: 600px;
            img{
                filter: sepia(20%);
                padding: 10px;
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
            }
        }
        &__2{
            width: 50%;
            height: 600px;
            display: flex;
            flex-direction: column;
            img{
                filter: sepia(20%);
                padding: 10px;
                width: 100%;
                height: 50%;
                object-position: center;
                object-fit: cover;
            }
        }
    }
    &__right{
        width: 30%;
        padding-right: 1rem;
        p{
        font-size: 12px;
        }
    }
}

.circle{
    position: absolute;
    left: -5rem;
    bottom: -10rem;
    width: 700px;
    height: 700px;
}

@media (max-width: 1200px) {
    .block{
        &__block{
            &__text{
                width: 100%;
            }
        }
    }
    .footer{
        flex-direction: column;
        height: 100%;
        &__left{
            width: 100%;
        }
        &__right{
            z-index: 3;
            width: 100%;
        }
    }

    
}