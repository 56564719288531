.type{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: rgba(128, 128, 128, 0.8);
    &__right{
        position: relative;
        width: 30%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 100%;
        &__block{
            cursor: pointer;
            width: 50%;
            height: 33.33%;
            padding: 2px;
            perspective: 1200;
            transform-style: preserve-3d;
            border-radius: 12px;
            &:hover .flipper{
                transform: rotateY(180deg);
            }
        &:nth-child(5){
            display: flex;
                cursor: default;
        } 
            &:last-child{
                display: flex;
                cursor: default;
            }
        }
    }
    &__left{
        position: relative;
        width: 70%;
        height: 100vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        filter: sepia(10%);
        background-image: url(../../../../public/image/type/001.jpg);
    }
    &__title{
        width: 100%;
        margin-top: 20%;
        padding-right: 3rem;
        display: flex;
        flex-direction: row;
        cursor: default;
        writing-mode: vertical-rl;
        color: #161a1f;
        h1{
            font-size: 50px;
            letter-spacing: 2px;
            font-weight: 900;
            filter: contrast(200%);
        }
    }

}

.flipper {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: .7s linear;
    border-radius: 12px;  
  }
  .front, .back {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 12px;
    color: white;
  }
  .front {
   
    z-index: 2;
    transform: rotateY(0deg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;      
    
  }
  .back {
    transform: rotateY(-180deg);
    background: #161a1f;
    color: #b4afae;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3{
        margin: 0 auto;
        font-size: 22px;
    }
    p{
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 3rem;
        color: white;
    }
  }

.bg1{
        background-image: url(../../../../public/image/type/002.jpg);
    }
.bg2{
        background-image: url(../../../../public/image/type/003.jpg);
    }
.bg3{
        background-image: url(../../../../public/image/type/004.jpg);
    }
.bg4{
        background-image: url(../../../../public/image/type/005.jpg);
    }
.bg5{
        background-image: url(../../../../public/image/type/006.jpg);
    }

.box{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(22, 26, 31, 0.3) ;
    border-radius: 12px;
    h2{
        padding: 1rem;
        font-size: 22px;
        filter: contrast(200%);
        font-weight: 900;
    }
    p{
        display: none;
        padding-left: 1rem;
    
    }
}


@media (max-width: 1199px) {
    .type{
        flex-direction: column-reverse;
        &__right{
            width: 100%;
        }
        &__left{
            width: 100%;
        }
        &__title{
            margin-top: 1rem;
            padding-left: 1rem;
            padding-right: 0;
            writing-mode: initial;
            h1{
                font-size: 30px;
            }
        }
    }
    .back p{
        display: none;
    }
    .box{
        h2{
            font-size: 18px;
            padding: 12px;
            margin: 0;
        }
        p{
            display: block;
            margin: 0;
            padding-top: 0;
            font-size: 12px;
            padding-left: 12px;
            font-weight: 800;
        }
    }
}


            
