.modal{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    overflow: hidden;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, .7);
    transition: opacity .3s;
    svg{
        color: white;
        font-size: 35px;
    }
    &__wrapper{
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 100%;
    }
    &__content{
        width: 320px;
        height: 510px;
        position: relative;
        transform: translateX(-50px);
        transition: all .3s;
    }
    &__close__btn{
        margin-left: 350px;
    }
    &__entering &__exited{
        opacity: 0;
    }
    &__entering .modal__content &__exited .modal__content{
        transform: translateX(-50px);
    }

}