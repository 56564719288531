.header{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(22, 26, 31, 0.7);
    padding-bottom: 0.5rem;
    &__right{
        margin-right: 1rem;
        &__logo{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    h2{
        margin-top: 1rem;
        margin-right: 1rem;
        margin-bottom: 0.5rem;
        color: white;
        font-size: 15px;
    }
}
.logo{
    margin-top: 1rem;
    
    width: 150px;
    filter: invert(100%) grayscale(100%) contrast(200%);
}