.body{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    // height: 490px;
    background-color: #283e4e;
    border-radius: 20px;
    box-shadow:8px 8px 12px 0px #000000;
    color: white;
    text-align: center;
    h2{
        margin-top: 1.5rem;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 2rem;
    }
    h3{
        font-size: 19px;
        font-weight: 800;
    }
    &__form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__block{
            width: 100%;
            margin-top: 1rem;
            margin-bottom: 1rem;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 2rem;
            label{
                position: absolute;
                bottom: 2px;
                left: 20%;
                pointer-events: none;
                transition: transform 0.2s ease-out, font-size 0.2s ease-out;
            }
            input{
                width: 90%;
                height: 35px;
                border: 1px solid rgba(240, 205, 153, 0.8);
                border-radius: 6px;
                font-size: 17px;
                color: white;
                padding-left: 7px;
            }
            &:nth-child(4){
                padding-top: 0;
                margin-top: 0;
                margin-bottom: 0;
            }
            
        }
        p, a{
                font-size: 8px;
                text-align: right;
                color: white;
                padding-right: 8px;
                padding-bottom: 8px;
                margin: 0;
            }
        button{
            margin-top: 1rem;
            margin-bottom: 1rem;
            width: 90%;
            height: 45px;
            background-color: rgba(240, 205, 153, 0.8);
            border: 1px solid rgba(240, 205, 153, 0.8);
            border-radius: 6px;
            font-size: 14px;
            text-transform: uppercase;
            color: #161a1f;
            letter-spacing: 2px;
            padding-left: 7px;
            font-weight: 900;
            transition: all .3s ease;
            &:hover{
                transform: scale(1.05);
                filter:invert(33%) contrast(200%);
            }
            &:active{
                filter: invert(100%);
            }
        }
    }


}

.active {
    transform: translateY(-30px) ;
    font-size: 0.8em;
    padding-bottom: 8px;
    // color: rgba(240, 205, 153, 0.8);
  }

.successMessage{
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 350px;
    width: 100%;
    &__circle{ 
        svg{
            font-size: 70px;
            font-weight: 200;
            color:rgb(255, 255, 255);
            animation-name: rotate-out-center;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-delay: 0s;
            animation-iteration-count: 1;
            animation-direction: normal;
            animation-fill-mode: none;
            opacity: 1;
        }
    }
    p{
        padding-bottom: 1rem;
        animation-name: fade-in;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: none;
            }
}


@keyframes rotate-out-center {
			
    0% {
        
        opacity: 0;
    }
    20%{
        opacity: 0;
    }
    30% {
        
        opacity:.5;
    }
    40% {
        
        opacity:0;
    }

    60% {
        
        opacity:0;
    }
    70% {
        
        opacity:.5;
    }
    80% {
        
        opacity:0;
    }
    90% {
        
        opacity:.5;
    }
    100% {
        
        opacity:1;
    }
}
@keyframes fade-in {
			
    0% {
        opacity:0;
    }
    80%{
        opacity: 0;
    }
    100% {
        opacity:1;
    }
}