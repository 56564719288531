
.burger{
    z-index: 9;
    position: fixed;
    display: flex;
    flex-direction: row;
    bottom: 1rem;
    right: 1rem;
    width: 50px;
    height: 50px;
    cursor: pointer;
    &__toggle{
        width: 100%;
        height: 7px;
        background-color: #e5e9e0;
        margin-bottom: 10px;
        border-radius: 3px;
    }
}
.menu{
    position: absolute;
    bottom: 0;
    right: -60px;
    height: 100vh;
    width: 300px;
    background-color: rgba(180, 175, 174, 0.7);
    transition: all .3s ease;
    animation: fade .3s linear;
    &__list{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        bottom: 10%;
        left: 2rem;
        writing-mode: initial;
        transform: rotate(180deg);

    }
}

.cross {
    .burger__toggle {
      &:first-child {
        transition: all .2s ease;
        transform: rotate(-45deg) translate(-20px, 0px);
        
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:last-child {
        transition: all .2s ease;
        transform: rotate(45deg) translate(-20px, 0px);
        
      }
    }
  }

.block{
    width: 180px;
    height: 80px;
    border-radius: 8px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 2rem 0.5rem ;
    box-shadow:3px 3px 10px 1px rgba(0, 0, 0, 0.8) ;
    filter: contrast(120%);
    transition: all .3s ease;
    &:hover{
        filter: contrast(100%);
           transform: scale(1.04); 
        }
        &:active{
            filter: blur(3px);
            transform: scale(1.02); 
        }
    &__bg{
        position: relative;
        width: 180px;
        height: 80px;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.4);
        &:hover{
            background-color: transparent;
        }
        p{
            padding-top: 1rem;
            padding-left: 1rem;
            font-size: 18px;
            color: #f0cd99;
            font-weight: 600;
            filter: contrast(150%);

        }
        svg{
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            font-size: 25px;
            color: #f0cd99;
            font-weight: 900;
            filter: contrast(150%);
            
        }
    }
}

li:nth-child(1) .block{
    background-image: url(../../../../public/image/menu/001.jpg);
}
li:nth-child(2) .block{
    background-image: url(../../../../public/image/menu/002.jpg);
}
li:nth-child(3) .block{
    background-image: url(../../../../public/image/menu/003.jpg);
}
li:nth-child(4) .block{
    background-image: url(../../../../public/image/menu/004.jpg);
}
li:nth-child(5) .block{
    background-image: url(../../../../public/image/menu/005.jpg);
}

.logo{
    margin-top: 5rem;
    width: 180px;
    height: 100px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../../../public/image/logo/logo.png);
    filter: grayscale(100%) contrast(150%);
}

@keyframes fade {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}



@media (max-width: 1299px) {
    .burger{
        display: none;
    }
}