.main{
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    background:linear-gradient(90deg, #e9e5e0 0%, #d7c1b1 90%);
    &__left{
        display: flex;
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        width: 20%;
        height: 100%;
        background-position: 25% 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(../../../../public/image/services/bg.jpg);
        &__title{
            width: 30%;
            position: absolute;

            top: 3rem;
            left: 0.5rem;
            margin-top: 2px;
            padding-right: 1rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            cursor: default;
            writing-mode: vertical-rl;
            transform: scale(-1, -1);
            color: #161a1f;
            h1{
                font-size: 45px;
                letter-spacing: 2px;
                font-weight: 900;
                margin: 0;
                padding-top: 0;
                padding: 1rem;
                filter: contrast(200%);
            }
        }
        &__right{
            width: 70%;
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            margin-top: 150px;  
        }
    }
    &__right{
        position: absolute;
        top: 0;
        right: 0;
        width: 80%;
        height: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        background:linear-gradient(90deg, #e9e5e0 0%, #d7c1b1 90%);
        &__btn{
            width: 100%;
            margin-top: 2rem;
            padding-right: 3rem;
            text-align: right;
        }
        &__box{
            display: flex; 
            flex-direction: column;
            width: 100%;
            position: relative;
        }


    }
}

@for $i from 1 through 5 {
    .box#{$i}{
        width: 100%;
        height: 140px;
        position: relative;
        cursor: pointer;
        filter: contrast(130%);
        margin-bottom: 12px;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(../../../../public/image/services/#{$i}.jpg);
        h3{
            position: absolute;
            bottom: 1rem;
            left: 1rem;
            font-weight: 900;
            font-size: 16px;
            text-shadow: 8px 8px 12px 0px #000000;
            text-transform: uppercase;
            color: white;
        }
    }
}
.front{
    width: 100%;
    height: 100%;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    background-color: rgba(22, 26, 31, 0.4);
}

.active{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    width: 100%;
    height: 140px;
    z-index: 10;
    filter: grayscale(80%) blur(1px);
    
}
.text{
    flex: 1;
    display: none;
    margin-top: 2rem;
    transition: all .3s ease;
    padding-bottom: 2rem;
}

.fade{
    display: block;
    padding: 0;
    margin: 0;
}


.media{
    display: none;
    text-align: right;
    flex-direction: row;
    width: 100%;
    background: rgba(22, 26, 31, 0.7);
    ul{
        margin-top: 1rem;
        width: 100%;
    }
    li{
        color: white;
        cursor: pointer;
        margin-bottom: 1rem;
        margin-right: 2rem;
        font-weight: 900;


    }
}

.modalbtn{
    width: 280px;
    height: 60px;
    border: 1px solid rgb(21, 25, 30);
    border-radius: 12px;
    font-size: 16px;
    padding: 0.5rem 2rem;
    letter-spacing: 2px;
    transition: all .3s ease;
    font-weight: 900;
        &:hover{
            transform: scale(1.05);
            filter:invert(33%) contrast(200%);
        }
        &:active{
            filter: invert(100%);
        }

}
@media (max-width: 1200px) {
    .main{
        flex-direction: column;
        &__left{
            display: none;
            &__title{
                text-align: right;
                margin-top: 1rem;
                padding-left: 3rem;
                padding-right: 1rem;
                padding: 0;
                writing-mode: initial;
                transform: initial;
            }
        }
        &__right{
            position: relative;
            width: 100%;
            &__box{
                width: 100%;
            }
        }
    }
    .media{
        display: flex;
    }
}

