.block{
    background: transparent;
    padding-right: 3rem;
    h6{
        font-size: 16px;

    }
    a{
        color: white;
    }
}