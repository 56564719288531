.nav{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    svg{
        color: white;
        font-size: 20px;
        margin-right: 3rem;
        margin-bottom: 1rem;
    }
    &__top{
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
            &__list{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 50%;
            text-align: right;
            font-size: 14px;
            font-weight: 600;
            &:last-child{
                padding-right: 1rem;
            }
            li{
                padding-bottom: 1rem;
            }
            a{
                text-transform: uppercase;
                color: white;
                font-size: 10px;
                font-weight: 400;
                
            }
            h6{
                color: white;
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 1rem;    
                font-style: italic;
            }
        }
    }
    &__address{
        font-size: 10px;
        width: 100%;
        color: white;
        text-align: right;
    }
    
}