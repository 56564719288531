.main{
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    background:linear-gradient(90deg, #e9e5e0 0%, #d7c1b1 90%);
    &__left{
        position: fixed;
        height: 100%;
        top: 0;
        left: 0;
        width: 20%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url(../../../../../public/image/type/bg.jpg);
        &__title{
            width: 100%;
            margin-top: 20%;
            padding-right: 3rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            cursor: default;
            writing-mode: vertical-rl;
            transform: scale(-1, -1);
            color: #161a1f;
            h1{
                font-size: 45px;
                letter-spacing: 2px;
                font-weight: 900;
                margin: 0;
                padding-top: 0;
                padding: 1rem;
            }
        }
    }
    &__right{
        position: absolute;
        top: 0;
        right: 0;
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background:linear-gradient(90deg, #e9e5e0 0%, #d7c1b1 90%);
        &__top{
            width: 100%;
            display: flex;
            flex-direction: row;
            background:linear-gradient(90deg, #e9e5e0 0%, #d7c1b1 90%);
            &__left{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 50%;
                text-align: right;
                padding-right: 1rem;
                padding-top: 2rem;
                padding-bottom: 2rem;
                h2, h3{
                    margin-right: 1rem;
                    margin-left: 2rem;
                    margin-bottom: 2rem;
                    font-size: 18px;
                }
            }
            &__right{
                width: 50%;
                min-height: 300px;
                img{
                    width: 100%;
                    object-position: bottom;
                    object-fit: cover;
                }
            }  
        }
        &__center{
        background:linear-gradient(90deg, #e9e5e0 0%, #d7c1b1 90%);
            display: flex;
            flex-direction: row;
            width: 100%;
            margin: 0;
            padding: 0;
           &__left{
                width: 33%;
                img{
                    width: 100%;
                    object-position: bottom;
                    object-fit: cover;
                }
            } 
            &__center{
                width: 34%;
                height: 100%;
                text-align: right;
                h4{
                    padding-top: 2rem;
                    padding-right: 2rem;
                    padding-left: 2rem;
                    font-size: 22px;
                }
                li{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex-wrap: wrap;
                    text-align: center;
                    svg{
                        font-size: 40px;
                        margin-top: 1rem;
                        margin-bottom: 1rem;
                    }
                    span{
                        
                        font-size: 18px;
                        font-weight: 900;
                        width: 70%;
                        margin-bottom: 1rem;
                    }
                    p{
                        font-size: 18px;
                        font-family: Philis;
                        font-style: italic;
                    }

                }
            }
            &__right{
                width: 33%;
                img{
                    width: 100%;
                    object-position: bottom;
                    object-fit: cover;
                }
            } 
        }
        &__bottom{
            width: 100%;
            // margin-top: 3rem;
            background:linear-gradient(90deg, #e9e5e0 0%, #d7c1b1 90%);
            &__top{
                
                background:linear-gradient(90deg, #e9e5e0 0%, #d7c1b1 90%);
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;    
                align-items: center;
                padding-top: 2rem;
                padding-bottom: 3rem;
                &__line{ 
                    background:linear-gradient(90deg, #e9e5e0 0%, #d7c1b1 90%);
                    width: 30%;
                    height: 1px;
                    background: #161a1f;
                }
                &__icon{
                    margin-left: 2rem;
                    margin-right: 2rem;
                    font-size: 60px;
                    color: #161a1f;
                }
            }
            &__center{
                margin-left: 2rem;
                margin-right: 2rem;
                text-align: center;
                h5{
                    line-height: 34px;
                    font-size: 20px;
                    font-weight: 900;
                    margin-bottom: 2rem;
                    padding-bottom: 2rem;
                }
                p{
                    font-size: 18px;
                    padding-bottom: 2rem;
                    font-weight: 800;
                }
            }
            &__bottom{
                display: flex;
                flex-direction: row;
                width: 100%;
                padding-bottom: 1rem;
                background-color: rgba(22, 26, 31, 0.7);
                &__img{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: flex-start;
                    width: 50%;
                    margin: 0;
                    padding: 0;
                }
                &__footer{

                    width: 50%;
                    padding-right: 1rem;
                    padding-bottom: 2rem;
                }
            }
        }
    }

    
}

.img{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    &:last-child{
        flex-direction: row-reverse;
    }
    img{
        filter: sepia(20%);
        cursor: default;
        width: 100%;
        object-position: bottom;
        object-fit: cover;
        padding: 5px;
        transition: all .5s ease;
        &:hover{
            filter: sepia(0%) contrast(140%);
        }
    }
    &__left{
       width: 50%;
        img{
            height: 380px;
        }
    }
    &__right{
        width: 50%;
        height: 50%;
        display: flex;
        flex-direction: column;
        img{
            height: 190px;
        }

    }
}
.modalbtn{
    width: 280px;
    height: 60px;
    margin-top: 2rem;
    border: 1px solid rgb(21, 25, 30);
    border-radius: 12px;
    font-size: 16px;
    padding: 0.5rem 2rem;
    letter-spacing: 2px;
    transition: all .3s ease;
    font-weight: 900;
    margin-bottom: 2rem;
        &:hover{
            transform: scale(1.05);
            filter:invert(33%) contrast(200%);
        }
        &:active{
            filter: invert(100%);
        }

}

@media (max-width: 1200px) {
    .main{
        flex-direction: column;
        &__left{
            width: 100%;
            
            &__title{
                writing-mode: initial;
                transform: initial;
            }
        }
        &__right{
            width: 100%;
            &__top{
                flex-direction: column-reverse;
                &__left{
                    width: 100%;
                }
                &__right{
                    width: 100%;
                }
            }
            &__center{
                flex-direction: column;
                &__left{
                    width: 100%;
                }
                &__center{
                    width: 100%;
                }
                &__right{
                    width: 100%;
                }
            }
            &__bottom{
                &__bottom{
                    flex-direction: column;
                    &__img{
                        width: 100%;
                    }
                    &__footer{
                        width: 100%;
                    }
                }
            }
        }
    }
}