.bs{
    margin-top: 2rem;
    margin-left: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    a, span{
    color: white;
        font-size: 12px;
    }
    &__link{
        
        &__now{
            color: #f0cd99;
            font-size: 12px;  
        }
    }
}