.widget{
    z-index: 6;
    position: fixed;
    bottom: 20px;
    right: 60px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
}
.widget__one{
    position: absolute;
    bottom: 20px;
    right: 60px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #0f3266;
    animation-name: circle-one;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    opacity: 0;
}

.widget__two{
    position: absolute;
    bottom: 20px;
    right: 60px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #0f3266;
    animation-name: circle-two;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 2s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    opacity: 0;
}


.widget__there{
    position: absolute;
    bottom: 20px;
    right: 60px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #0f3266;
    animation-name: circle-there;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-delay: 4s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    opacity: 0;
}


.widget__icons{
    z-index: 7;
    position: absolute;
    display: flex;
    bottom: 23px;
    right: 63px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: #0f3266;
    cursor: pointer;
    color: white;
}

.icons{
    position: absolute;
    margin-top: 7px;
    margin-left: 7px;
    width: 30px;
    height: 30px;
    opacity: 0;
    animation-name: icons;
    animation-duration: 8s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;

}
.icons1{
    position: absolute;
    margin-top: 7px;
    margin-left: 7px;
    width: 30px;
    height: 30px;
    opacity: 0;
    animation-name: icons1;
    animation-duration: 8s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;

}
.icons2{
    position: absolute;
    margin-top: 7px;
    margin-left: 7px;
    width: 30px;
    height: 30px;
    opacity: 0;
    animation-name: icons2;
    animation-duration: 8s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
}
.icons3{
    position: absolute;
    margin-top: 7px;
    margin-left: 7px;
    width: 30px;
    height: 30px;
    opacity: 0;
    animation-name: icons3;
    animation-duration: 8s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    
}
.icons4{
    position: absolute;
    margin-top: 7px;
    margin-left: 7px;
    width: 30px;
    height: 30px;
    opacity: 0;
    animation-name: icons4;
    animation-duration: 8s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
}

.widget__window{
    display: none;
    position: relative;
    bottom: 330px;
    right: 10px;
    
}
.window__icons{
    font-size: 30px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-shadow:  0 4px 10px #0f3266;
}

.widget__list{
    padding: 2rem 1rem 1rem 1rem;
    text-align: center;
    background-color: rgba(214, 182, 159, 0.8);
    box-shadow:  0 4px 10px #0f3266;
    border-radius: 18px;
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    
}
.widget__list li{
    padding-bottom: 1rem;
}
.widget__list a{
    
    transition: all .4s ease;
}
.widget__list a:hover{
    filter: blur(2px) hue-rotate(45deg);
}

.active{
    display: block;
    animation-name: fade-in-bottom;
	animation-duration: .6s;
	animation-timing-function: linear;
	animation-delay: 0s;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: none;
}

@keyframes icons {
    0%{
        opacity: .6;
    }
    12%{
        opacity: 0;
    }
    96%{
        opacity: 0;
    }
    100%{
    opacity: .6;
    }
}
@keyframes icons1 {
    0%{
        opacity: 0;
    }
    12%{
        opacity: 0;
    }
    24%{
        opacity: .6;
    }
    36%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}
@keyframes icons2 {
    0%{
        opacity: 0;
    }
    36%{
        opacity: 0;
    }
    48%{
        opacity: .6;
    }
    60%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}
@keyframes icons3 {
    0%{
        opacity: 0;
    }
    60%{
        opacity: 0;
    }
    72%{
        opacity: .6;
    }
    84%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}
@keyframes icons4 {
    0%{
        opacity: 0;
    }
    60%{
        opacity: 0;
    }
    72%{
        opacity: 0;
    }
    84%{
        opacity: 0;
    }
    96%{
        opacity: .6;
    }
    100%{
        opacity: .3;
    }
}


@keyframes circle-one {
    0%{
      opacity: 0;
      transform: scale(1);
    }
    50%{
      opacity: .1;
      transform: scale(1.2);
    }
    100%{
      opacity: 0;
      transform: scale(1);
    }
}
@keyframes circle-two {
    0%{
      opacity: 0;
      transform: scale(1);
    }
    50%{
      opacity: .5;
      transform: scale(1.3);
    }
    100%{
      opacity: 0;
      transform: scale(1);
    }
}
@keyframes circle-there {
    0%{
      opacity: 0;
      transform: scale(1);
    }
    50%{
      opacity: .5;
      transform: scale(1.4);
    }
    100%{
      opacity: 0;
      transform: scale(1);
    }
}	
@keyframes fade-in-bottom {		
    0% {
		transform:translateY(50px);
		opacity:0;
	}
	100% {
		transform:translateY(0);
		opacity:1;
	}
}

@media (max-width: 992px) {
    .widget{
        bottom: 20px;
        right: -25px;
    }
}