.contact{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    background:linear-gradient(90deg, #e9e5e0 0%, #d7c1b1 90%);
    &__left{
        position: relative;
        width: 20%;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(../../../../public/image/contact/bg.jpg);
    }
    &__right{
        width: 80%;
        position: relative;
        &__body{
            margin-top: 3rem;
            display: flex;
            flex-direction: row;
            &__left{
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: 2rem;
                margin-top: 2rem;
                &__title{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                   h1{
                        font-size: 34px;
                    }
                    h2{
                        font-size: 17px;
                        font-family: Philis;
                    } 
                }
                &__icon{
                    
                    font-size: 34px;
                    margin-top: 2rem;
                    svg{
                        color: #161a1f;
                        margin-left: 2rem;
                    }
                }
                &__inn{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    margin-top: 3rem;
                    padding-bottom: 2rem;
                    padding-right: 1rem;
                    h5{
                        font-size: 18px;
                        margin-bottom: 1rem;
                    }
                    p{
                        font-size: 11px;
                        padding: 0;
                        margin: 0;
                    }
                    a{
                        font-size: 10px;
                        padding-top: 2rem;
                    }
                }
                
            }
            &__right{
                width: 50%;
                &__address{
                    width: 90%;
                    margin-top: 2rem;
                    padding-right: 1.5rem;
                    text-align: right;
                    h6{
                        font-size: 20px;
                        font-family: Philis;
                        font-weight: 600;
                    }
                    p{
                        margin: 0;
                        padding: 0;
                        padding: 0.5rem;
                        font-weight: 900;
                        font-size: 14px;
                    }
                }
                &__map{
                    margin-top: 2rem;
                    margin-left: 1rem;
                    width: 90%;
                    padding: 0;
                    box-shadow:8px 8px 12px 0px #000000;
                }
            }
        }
    }
}

.line{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    &__line{
        width: 30%;
        margin-right: 1rem;
        margin-left: 1rem;
        height: 1px;
        background-color: #161a1f;
    }
    &__icon{
        color: #161a1f;
        font-size: 50px;
    }
}

@media (max-width: 1200px) {
    .contact{
        flex-direction: column;
        height: 100%;
        &__left{
            width: 100%;
        }
        &__right{
            width: 100%;
            &__body{
                flex-direction: column-reverse;
                &__left{
                    width: 100%;
                }
                &__right{
                    width: 100%;
                }
            }
        }
    }
}