.navigation{
    border-bottom: 1px solid white;
    margin-top: 1rem;
    margin-left: 2rem;
    padding-top: 1em;
    padding-bottom: 1rem;
    &__list{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;
        li{
            margin-right: 1.5rem;
            display: flex;
            border-bottom: 2px solid transparent;
            transition: all .5s ease;
            &:hover{
                filter: invert(30%);
                border-bottom: 2px solid white;
            }
        }
        &__block{
            display: flex;
            flex-direction: row;
            align-items: center;
            color: white;
            p{
                padding: 0;
                margin: 0;
                font-weight: 900;
            }
            svg{
                margin-right: 6px;
            }
        }
    }
}