@font-face {
    font-family: Comforta;
    src: url('../fonts/Comfortaa.ttf');
}
@font-face {
    font-family: Philis;
    src: url('../fonts/philosopher.ttf');
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Comforta;
}
ul, li, ol{
    list-style: none;
    border: none;
    text-decoration: none;
}
button, input{
    background-color: transparent;
    outline: none;
    border: none;
    color: #161a1f;
}
a{
    color: #161a1f;
    border: none;
    text-decoration: none;
    outline: none;
}