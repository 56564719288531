.body{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: rgb(240, 205, 153);

}
.text{
    text-align: center;
    opacity: 1;
    animation-name: text-focus-in;
    text-shadow:0px 15px 18px rgba(0,0,0,1);
	animation-duration: 2s;
	animation-timing-function: linear;
	animation-delay: 0s;
	animation-iteration-count: 1;
    animation-direction: normal;
	animation-fill-mode: none;
    font-family: Philis;
    h3{
        font-size: 35px;
        font-weight: 900;
    }
    h4{
        font-size: 35px;
        text-transform: uppercase;
        font-weight: 900;
    }
}

@keyframes text-focus-in {
			
    0% {
        filter:blur(12px);
        opacity:0;
        text-shadow:0 0 0 transparent;
    }
    50% {
        filter:blur(0);
        opacity:1;
        text-shadow:0 0 0 transparent;
    }
    100% {
        text-shadow:0px 15px 18px rgba(0,0,0,1);
        opacity:1;
    }

}