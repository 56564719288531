$easing: cubic-bezier(0.39, 0.575, 0.565, 1);

.main{
    position: relative;
    width: 100%;
    height: 100vh;
    &__body{
        display: flex;
        flex-direction: row;
        &__left{
            position: relative;
            width: 50%;
            height: 100vh;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../../../../public/image/main/1.jpg);
            &__head{
                height: 100%;
                margin-left: 3rem;
                padding-top: -rem;
                display: flex;
                flex-direction: column-reverse;
                flex-wrap: nowrap;
                color: white;
                writing-mode: vertical-lr;
                transform: scale(-1, -1);
                align-items: center;
                a{
                    color: white;
                }
                h1{
                  font-size: 50px;
                  font-weight: 900;
                }
                h2{
                    font-size: 22px;
                    margin-bottom: 3rem;
                }
            }
        }
        &__right{
            width: 50%;
            height: 100vh;
            display: flex;
            flex-direction: column;
            &__top{
                width: 100%;
                height: 60%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                &__left{
                    position: relative;
                    width: 50%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                    background: hsl(36, 74%, 77%);
                    &__made{
                        width: 100%;
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-end;
                        flex-direction: column;
                        padding: 0;
                        writing-mode: vertical-rl;
                        transform: scale(-1, -1);
                        text-align: right;
                        color:#839591;
                        font-size: 12px;
                        & p:nth-child(2){
                            padding-left: 1rem;
                        }
                    }
                    &__text{
                        &__years{
                            display: flex;
                            align-items: center;
                            font-size: 13px;
                            padding: 0;
                            margin-left: 3rem;
                            font-weight: 900;
                            font-family: Philis;
                            & .line{
                                display: flex;
                                align-items: center;
                                width: 3rem;
                                height: 1px;
                                background-color: black;
                            }
                        }
                        width: 100%;
                        &__body{
                            color: #161a1f;
                            cursor: grab;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            h3{
                                width: 300px;
                                font-size: 20px;
                                font-weight: 900;
                                margin-right: 3rem;
                                margin-bottom: 1rem;
                                text-align: right;
                                line-height: 21px;
                                letter-spacing: -1px;
                            }
                            p{
                                text-align: right;
                                margin-right: 1rem;
                                margin-left: 1rem;
                            }
                        }
                    }
                }
                &__right{
                    position: relative;
                    width: 50%;
                    height: 100%;
                   overflow: hidden;
                       
                }
            }
            &__bottom{
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 40%;
                &__left{
                    position: relative;
                    height: 100%;
                    width: 33%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-image: url(../../../../public/image/main/003.jpg);
                }
                &__center{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    width: 34%;
                    background-color: #b4afae;
                    &__top{
                        display: flex;
                        flex-direction: column;
                        padding-right: 1rem;
                        margin-top: 5rem;
                        text-align: right;
                        & h4{
                            font-family: Philis;
                            font-weight: 500;
                            font-size: 18px;
                            text-align: right;
                            font-size: 18px;
                            letter-spacing: 1px;
                        }
                        &__icons{
                            a {
                            cursor: pointer;
                            color: #161a1f;
                            font-size: 35px;
                            margin-top: 4px;
                            margin-left: 8px;
                            padding: 3px;
                            font-weight: 900;
                            transition: all .3s ease;
                            &:hover{
                                transform: scale(2);
                                filter:invert(33%) contrast(200%);
                            }
                            &:active{
                                filter: invert(100%);
                            }}
                        }
                    }
                }
                &__right{
                    position: relative;
                    height: 100%;
                    width: 33%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-image: url(../../../../public/image/main/002.jpg);
                }
            }
        }
    }
    &__send{
        margin-left: 3rem;
        margin-bottom: 2rem;
        &__btn{
            border: 1px solid #161a1f;
            padding: 0.5rem 2rem;
            border-radius: 6px;
            transition: all .3s ease;
            font-weight: 900;
            &:hover{
                transform: scale(1.05);
                filter:invert(33%) contrast(200%);
            }
            &:active{
                filter: invert(100%);
            }
        }
    }
}
.buster{
    display: flex;
    flex-direction: row;
    justify-content: end;
    &__contact{
        margin-top: 1rem;
        h6, p{
            font-family: Philis;
            font-weight: 700;
            font-size: 12px;
            margin: 0;
            padding: 0;
        }
    }
    &__inn{
        position: absolute;
        display: flex;
        flex-direction: column;
        right: 1rem;
        bottom: 1rem;
        p{
            font-size: 11px;
            margin: 0;
            padding: 0;
            font-family: Philis;
            font-weight: 500;
            align-items: flex-end;
        }
        a{
            font-size: 9px;
            margin-top: 8px;
        }
    }
    &__line{
        width: 100%;
        height: 1px;
        margin-top: 2rem;
        margin-left: 3px;
        margin-right: 3px;
        background-color: #161a1f;
    }
}
.one{
    height: 100vh;
    width: 100%;
    max-height: 700px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../../../public/image/main/swiper/001.jpg);
}
.two{
    height: 100vh;
    width: 100%;
    max-height: 700px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../../../public/image/main/swiper/002.jpg);
}
.therre{
    height: 100vh;
    width: 100%;
    max-height: 700px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../../../public/image/main/swiper/003.jpg);
}
.four{
    height: 100vh;
    width: 100%;
    max-height: 700px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../../../public/image/main/swiper/004.jpg);
}
.five{
    height: 100vh;
    width: 100%;
    max-height: 700px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../../../public/image/main/swiper/005.jpg);
}

.animate{
    position: absolute;
    overflow: hidden;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(240, 205, 153);
    z-index: 8;
    animation: background-swipe-out 1.5s cubic-bezier(0.785, 0.135, 0.15, 0.86)  1;
}


.modalbtn{
    width: 280px;
    height: 60px;
    border: 1px solid rgb(21, 25, 30);
    border-radius: 12px;
    font-size: 16px;
    padding: 0.5rem 2rem;
    letter-spacing: 2px;
    transition: all .3s ease;
    font-weight: 900;
        &:hover{
            transform: scale(1.05);
            filter:invert(33%) contrast(200%);
        }
        &:active{
            filter: invert(100%);
        }

}

@keyframes background-swipe-out {
	0%, 65% { width: 100%; }
	100% { width: 0%; }
}

@media (max-width: 1299px) {
    .main{
        &__body{
            flex-direction: column;
            &__left{
                width: 100%;
            }
            &__right{
                width: 100%;
                &__top{
                    flex-direction: column;
                    &__left{
                        width: 100%;
                    }
                    &__right{
                        display: none;
                    }
                }
                &__bottom{
                    
                    &__right{
                       display: none; 
                    }
                    &__center{
                        width: 100%;
                        height: 500px;
                    }
                    &__left{
                        width: 100%;
                        height: 500px;
                    }
                }
            }
        }
    }
    .buster{
        &__inn{
            position: relative;
            right: 0;
        }
    }
}